body {
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  background-color: #f6e6e0;
}

.ball-text {
  position: absolute;
  z-index: 2;
  color: rgb(0, 0, 0);
  left: 50%;
  bottom: 85%;
  transform: translate(-50%, -50%);
}

.arrow-style {
  position: absolute;
  z-index: 2;
  left: 25%;
  top: 4%;
}

#menu li {
  background-color: rgb(18, 30, 39);
  display: inline-block;
  margin-right: 20px;
}

.container-icons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4vw;
  justify-content: center;
}

.box-icon {
  width: 16.66%;
  text-align: center;
  margin-bottom: 20px;
}

.box-icon i {
  font-size: 3rem;
  display: block;
  margin: 0 auto;
}

.box-icon p {
  font-size: 0.8rem;
}

p {
  line-height: 3;
}

.nooice {
  background-color: rgb(179, 90, 90);
  color: white;
  border-radius: 10px;
  background-image: linear-gradient(to right, #6C63FF, #FF7F11);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.elecTitle {
  background-color: rgb(179, 90, 90);
  color: white;
  border-radius: 10px;
  background-image: linear-gradient(to right, #dd5b10, #9bd105);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.phyTitle {
  background-color: rgb(179, 90, 90);
  color: white;
  border-radius: 10px;
  background-image: linear-gradient(to right, #9bd105, #dd5b10);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title {
  padding-top: 2rem;
  color: rgb(176, 169, 169);
}

.text-center {
  color: rgb(210, 220, 17);
}

.box-icon i {
  font-size: 4rem;
}

.contact-icon {
  margin: 0.8rem;
}

.contact-icon i {
  font-size: 25px;
  color: #acacac;
  transition: color 0.5s ease;
}

.contact-icon i:hover {
  color: #fc9402;
}

footer a {
  text-decoration: none;
}

.about-title {
  padding-top: 1rem;
  color:rgb(176, 169, 169);
}
